import React from 'react'
import ProposalListComp from '../../components/proposalListComp/ProposalListComp'

function ProposalListPage() {
    return (
        <>
            <ProposalListComp />
        </>
    )
}

export default ProposalListPage