import React from 'react'
import CashfreeGateWayMaster from '../../../../components/topNavigationComp/masters/cashfreeGatewayMaster/CashfreeGateWayMaster'

const CashfreeGateWayMasterPages = () => {
  return (
    <>
      <CashfreeGateWayMaster/>
    </>
  )
}

export default CashfreeGateWayMasterPages
