import ViewProfileMember from "../../components/viewProfileMember/ViewProfileMember"


function ViewProfileMemberPage() {
    return (
        <>
            <ViewProfileMember />
        </>
    )
}
export default ViewProfileMemberPage