import UtiPancard from "../../components/utiPandcard/UtiPancard"


function UtiPancardPage() {
    return (
        <>
            <UtiPancard />
        </>
    )
}
export default UtiPancardPage