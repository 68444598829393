import React from 'react'
import IntrestList from './intrestList/IntrestList'
import IntrestFilter from './intrestFilter/IntrestFilter'

function Intrest() {
    return (
        <>
            <IntrestFilter />
            <IntrestList />
        </>
    )
}

export default Intrest