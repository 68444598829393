import React from 'react'
import AffilatedType from '../../components/topNavigationComp/masters/affilatedType/AffilatedTypes'

function AffilatedBy() {
  return (
    <>
     <AffilatedType/> 
    </>
  )
}

export default AffilatedBy
