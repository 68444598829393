import Contra from "../../../../components/transaction/bankCash/contra/Contra"


function ContraPage() {
    return (
        <>
            <Contra />
        </>
    )
}
export default ContraPage