import React from 'react'
import CompanyMaster from '../../components/topNavigationComp/masters/companyMaster/CompanyMaster'

function CompanyMasters() {
    return (
        <>
            <CompanyMaster />
        </>
    )
}

export default CompanyMasters
