import PurchaseChallan from "../../../../components/transaction/challan/purchaseChallan/PurchaseChallan"


const PurchaseChallanPage = () => {
    return (
        <>
            <PurchaseChallan />

        </>
    )
}

export default PurchaseChallanPage