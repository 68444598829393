import React from 'react'
import LeadSubStatus from '../../components/topNavigationComp/masters/leadSubStatusMaster/LeadSubStatus'

function LeadSubStatusMaster() {
  return (
    <>
      <LeadSubStatus/>
    </>
  )
}

export default LeadSubStatusMaster
