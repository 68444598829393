import PaymentRequestView from "../../components/paymentRequestView/PaymentRequestView"


function PaymentRequestViewPage() {
    return (
        <>
            <PaymentRequestView />
        </>
    )
}
export default PaymentRequestViewPage