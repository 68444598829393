import React from 'react'
import ProductFilter from './productFillter/ProductFilter'

function CapitalProduct() {
    return (
        <>
            <ProductFilter />
        </>
    )
}

export default CapitalProduct