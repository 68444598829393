import { PurchangefromChalan } from "../../../../components/transaction/challan/purchangefromChalan/PurchangefromChalan"


const PurchangefromChalanPage = () => {
    return (
        <>
            <PurchangefromChalan />
        </>
    )
}

export default PurchangefromChalanPage