import { DistributorCreditControl } from "../../../components/transaction/distributorCreditControl/DistributorCreditControl"




export const DistributorCreditControlPage = () => {
    return (
        <>
            <DistributorCreditControl />
        </>
    )
}
