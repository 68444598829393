import React from 'react'
import LeadStatus from '../../components/topNavigationComp/masters/leadStatusMaster/LeadStatus'

function LeadStatusMaster() {
  return (
    <>
      <LeadStatus/>
    </>
  )
}

export default LeadStatusMaster
