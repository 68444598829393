import React from 'react'
import CollegesType from '../../components/topNavigationComp/masters/collegeType/CollegesType'

function CollegetypesPage() {
  return (
    <>
      <CollegesType/>
    </>
  )
}

export default CollegetypesPage
