import CrmElectronic from "../../../components/transaction/crmElectronic/CrmElectronic"



export const CrmElectronicPage = () => {
    return (
        <>
            <CrmElectronic />
        </>
    )
}
