import React from 'react'
import BankMaster from '../../../../components/topNavigationComp/masters/bankMaster/BankMaster'

function BankMasterP() {
  
  return (
    <>
      <BankMaster/>
    </>
  )
}

export default BankMasterP
