import React from 'react'
import AddNewTaskComp from '../../components/addNewTaskComp/AddNewTaskComp'

function AddNewTaskPage() {
  return (
    <>
    <AddNewTaskComp />
    </>
  )
}

export default AddNewTaskPage