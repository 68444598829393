import React from 'react'
import FacilitiesMastes from '../../components/topNavigationComp/masters/facilitiesMaster/FacilitiesMaster'

function Facilities() {
  return (
    <>
      <FacilitiesMastes/>
    </>
  )
}

export default Facilities
