import StockTransfer from "../../../components/transaction/stockTransfer/StockTransfer"


const StocksTrasferPage = () => {
    return (
        <>
            <StockTransfer />
        </>
    )
}

export default StocksTrasferPage