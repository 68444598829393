import React from 'react'
import AddNewTaskTemplateComp from '../../components/addNewTaskTemplateComp/AddNewTaskTemplateComp'

function AddNewTaskTemplatePage() {
  return (
    <>
    <AddNewTaskTemplateComp />
    </>
  )
}

export default AddNewTaskTemplatePage