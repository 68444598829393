import ProductionPlanning from "../../../../components/transaction/productionsPlanning/productionPlanning/ProductionPlanning"


const ProductionPlaningPage = () => {
    return (
        <>
            <ProductionPlanning />
        </>
    )
}

export default ProductionPlaningPage