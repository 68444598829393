import PurchageOrder from "../../../../components/transaction/order/purchageOrder/PurchageOrder"


const PurchageOrderPage = () => {
    return (
        <>
            <PurchageOrder />
        </>
    )
}

export default PurchageOrderPage