import React from 'react'
import Dispute from '../../../components/dmtModule/disputes/Disputes'

function DmtDisputesPage() {
  return (
    <>
      <Dispute/>
    </>
  )
}

export default DmtDisputesPage
