import Estimate_request from "../../components/estimate_request/Estimate_request"


const Estimate_requestPage = () => {
    return (
        <>
            <Estimate_request />
        </>
    )
}

export default Estimate_requestPage