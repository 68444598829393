import { DeliveryChallan } from "../../../components/transaction/deliveryChallan/DeliveryChallan"


function DeliveryChallanPage() {
    return (
        <>
            <DeliveryChallan />
        </>
    )
}
export default DeliveryChallanPage