import Branch from "../../../components/catalog/branch/Branch"


function BranchPage() {
    return (
        <>
            <Branch />
        </>
    )
}
export default BranchPage