import ElectronicsDashboard from "../../components/electronicsDashboard/ElectronicsDashboard"




const ElectronicsDashboardPage = () => {
    return (
        <>
            <ElectronicsDashboard />
        </>
    )
}

export default ElectronicsDashboardPage