import { SalesFromOrder } from "../../../../components/transaction/challan/salesFromOrder/SalesFromOrder"



function SalesFromOrderPage() {
    return (
        <>
            <SalesFromOrder />
        </>
    )
}
export default SalesFromOrderPage