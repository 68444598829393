import { SaleDueCollection } from "../../../components/milkParlor/saleDueCollection/SaleDueCollection"


function SaleDueCollectionPage() {
    return (
        <>
            <SaleDueCollection />
        </>
    )
}
export default SaleDueCollectionPage