import Colorr from "../../../components/catalog/colorr/Colorr"


function ColorrPage() {
    return (
        <>
            <Colorr />
        </>
    )
}
export default ColorrPage