import ProductionIssue from "../../../../components/transaction/productionsPlanning/productionIssue/ProductionIssue"



const ProductionIssuePage = ({ Heading }) => {
    return (
        <>
            <ProductionIssue Heading={Heading} />
        </>
    )
}

export default ProductionIssuePage