import React from 'react'
import UserType from '../../../components/topNavigationComp/customer/userType/UserType'

const UsersType = () => {
  return (
    <>
      <UserType/>
    </>
  )
}

export default UsersType
