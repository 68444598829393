import WarehouseStockTransfer from "../../../components/logistics/warehouseStocktransfer/WarehouseStockTransfer"


function WarehouseStocktransferPage() {
    return (
        <>
            <WarehouseStockTransfer />
        </>
    )
}
export default WarehouseStocktransferPage