import CollectMilk from "../../../components/milkParlor/collectMilk/CollectMilk"


function CollectMilkPage() {
    return (
        <>
            <CollectMilk />
        </>
    )
}
export default CollectMilkPage