import CrmDistributionSales from "../../../components/transaction/crmDistributionSales/CrmDistributionSales"


export const CrmDistributionSalesPage = () => {
    return (
        <>
            <CrmDistributionSales />
        </>
    )
}
