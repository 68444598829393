import { Suppliers } from "../../components/suppliers/Suppliers"


function SuppliersPage() {
    return (
        <>

            <Suppliers />
        </>
    )
}
export default SuppliersPage