import React from 'react'
import CreditCardMaster from '../../components/topNavigationComp/masters/creditCardMaster/CreditCardMaster'

function CreditCard() {
  return (
    <>
      <CreditCardMaster/>
    </>
  )
}

export default CreditCard
