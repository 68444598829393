import ClosePendingDCManually from "../../../components/transaction/closePendingDCManually/ClosePendingDCManually"


const ClosePendingDCManuallyPage = () => {
    return (
        <>
            <ClosePendingDCManually />
        </>
    )
}

export default ClosePendingDCManuallyPage