import React from 'react'
import Master from '../../../components/topNavigationComp/masters/Master'
function MasterPages() {
  return (
    <>
      <Master/>
    </>
  )
}

export default MasterPages
