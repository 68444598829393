import CNEntrywithStock from "../../../../components/transaction/gst/CNEntrywithStock/CNEntrywithStock"



const CNEntrywithStockPage = () => {
    return (
        <>
            <CNEntrywithStock />
        </>
    )
}

export default CNEntrywithStockPage