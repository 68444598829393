import ServiceCategoryMaster from "../../../../components/topNavigationComp/masters/serviceCategoryMaster/ServiceCategoryMaster"


function ServiceCategoryMasterPage() {
    return (
        <>
            <ServiceCategoryMaster />
        </>
    )
}
export default ServiceCategoryMasterPage