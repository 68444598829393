import ChallanReturn from "../../../../components/transaction/challan/challanReturn/ChallanReturn"


const ChallanReturnPage = () => {
    return (
        <>
            <ChallanReturn />
        </>
    )
}

export default ChallanReturnPage