import ListCurrency from "./listCurrency/ListCurrency"


function Currency() {
    return (
        <>
            <ListCurrency />
        </>
    )
}
export default Currency