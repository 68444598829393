import React from 'react'
import ListGroupsSetAttributes from './listGroupsSetAttributes/ListGroupsSetAttributes'

function SetGroupAttributes() {
  return (
    <>
      <ListGroupsSetAttributes/>
    </>
  )
}

export default SetGroupAttributes
