import { CrmDistributionCollection } from "../../../components/transaction/crmDistributionCollection/CrmDistributionCollection"



const CrmDistributionCollectionPage = () => {
    return (
        <>
            <CrmDistributionCollection />
        </>
    )
}

export default CrmDistributionCollectionPage