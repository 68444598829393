import React from 'react'
import StockRegisterLis from './stockRegisterList/StockRegisterLis'

function StockRegister() {
    return (
        <>
        <StockRegisterLis/>
        </>
    )
}

export default StockRegister