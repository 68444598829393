import React from 'react'
import TaskTemplateListComp from '../../components/taskTemplateListComp/TaskTemplateListComp'

function TaskTemplateListPage() {
  return (
    <>
     <TaskTemplateListComp/>
    </>
  )
}

export default TaskTemplateListPage