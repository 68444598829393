import ManageStall from "../../components/manageStall/ManageStall"


function ManageStallPage() {
    return (
        <>
            <ManageStall />
        </>
    )
}

export default ManageStallPage