import GroupSummary from "../../../../components/electronicsReports/accountReport/groupSummary/GroupSummary"


const GroupSummaryPage = () => {
    return (
        <>
            <GroupSummary />
        </>
    )
}

export default GroupSummaryPage