import React from 'react'
import DmtstatusList from './dmtStatusList/DmtStatusList'

function DmtStatus() {
  return (
    <>
      <DmtstatusList/>
    </>
  )
}

export default DmtStatus
