import { ManageUsers } from "../../components/ManageUsers/ManageUsers"



function ManageUsersPage() {
    return (
        <>
            <ManageUsers />
        </>
    )
}
export default ManageUsersPage