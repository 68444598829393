import React from 'react';
import SaleInvoice from '../../components/saleInvoice/SaleInvoice';

function SalesIvoiceListPage() {
    return (
        <>
            <SaleInvoice />
        </>
    )
}

export default SalesIvoiceListPage