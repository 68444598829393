import { DeclaredWinners } from "../../components/declaredWinners/DeclaredWinners"


function DeclaredWinnersPage() {
    return (
        <>
            <DeclaredWinners />
        </>
    )
}
export default DeclaredWinnersPage