import React from 'react'
import PhotosGalleryMaster from '../../components/topNavigationComp/masters/photosGalleryMaster/PhotosGalleryMaster'

function GalleryDatas() {
  return (
    <>
      <PhotosGalleryMaster/>
    </>
  )
}

export default GalleryDatas
