import React from 'react'
import LocationWiseStockList from './locationWiseStockList/LocationWiseStockList'

function LocationWiseStock() {
    return (
        <>
            <LocationWiseStockList />
        </>
    )
}

export default LocationWiseStock