// Search.js
import React from 'react';

function Search() {
    return (
        <input type="text" placeholder="Search..." />
    );
}

export default Search;
