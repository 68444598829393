import React from 'react'
import CompanyStaff from '../../../components/topNavigationComp/companyStaff/CompanyStaff'

function CompanyStaffPages() {
  return (
    <>
      <CompanyStaff/>
    </>
  )
}

export default CompanyStaffPages
