import SalesChallan from "../../../../components/transaction/challan/salesChallan/SalesChallan"


const SalesChallanPage = () => {
    return (
        <>
            <SalesChallan />
        </>
    )
}

export default SalesChallanPage