import React from 'react'
import SerialNoStock from './serialNoStock/SerialNoStock'

function Serialnumber() {
    return (
        <div>
            <SerialNoStock />
        </div>
    )
}

export default Serialnumber