import { SalesInvoiceforUpdate } from "../../../components/transaction/salesInvoiceforUpdate/SalesInvoiceforUpdate"


export const SalesInvoiceforUpdatePage = () => {
    return (
        <>
            <SalesInvoiceforUpdate />
        </>
    )
}
