import React from "react";
import CreditNotes from "../../../components/sales/creditNotes/CreditNotes";

function CreditNotesPage() {
  return (
    <>
      <CreditNotes />
    </>
  );
}

export default CreditNotesPage;
