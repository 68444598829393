import EntryCnStocks from "../../../../components/transaction/gst/entryCnStocks/EntryCnStocks"


function EntryCnStocksPage() {
    return (
        <>
            <EntryCnStocks />
        </>
    )
}
export default EntryCnStocksPage